body {
  background-color: whitesmoke;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
  padding: 2px;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 15px;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: lightgray;
  margin: 15px 15px 0 0;
}
.contentBox {
  background-color: lightgray;
  border: 1px dashed transparent;
}

.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.active {
  background-color: #0099FF;
}

.jumbotron {
  background-color: lightgray;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

h4 {
  border:0 solid #fff;
  border-bottom-width:1px;
  padding-bottom:10px;
  text-align: center;
}

.vspacer5 { width:1px;height:5px;clear:both;}
.vspacer10 { width:1px;height:10px;clear:both;}
.vspacer20 { width:1px;height:20px;clear:both;}
.vspacer50 { width:1px;height:50px;clear:both;}
.vspacer80 { width:1px;height:80px;clear:both;}

:root {
  --amplify-primary-color: #337ab7;
  --amplify-primary-tint: #337ab7;
  --amplify-primary-shade: #337ab7;
  --amplify-background-color: lightgray;
}

amplify-authenticator {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  border-color:#d2d2d2;
  border-width: 5px;
  box-shadow:0 1px 0 #cfcfcf;
  width: 300px;
  margin: auto
}

.container {
  padding: 25px;
  position: fixed;
}

/* centered columns styles */
.row-centered {
  text-align:center;
}
.col-centered {
  display:inline-block;
  float:none;
  /* reset the text-align */
  text-align:left;
  /* inline-block space fix */
  margin-right:-4px;
}

.jumbotron {
  background-color: lightgray;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem
}
.form-login {
  background-color: lightgray;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  border-color:#d2d2d2;
  border-width: 5px;
  box-shadow:0 1px 0 #cfcfcf;
  width: 300px;
  margin: auto;
}

h4 {
  border:0 solid #fff;
  border-bottom-width:1px;
  padding-bottom:10px;
  text-align: center;
}

.form-control {
  border-radius: 10px;
}

.wrapper {
  text-align: center;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

body > .container {
  padding: 60px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.card {
  margin: 0 0 15px 15px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.crabTable {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

.crabTable tr td {
  border: 1px solid black;
  padding: 15px;
  text-align: center;
}

.crabTable th {
  border: 1px solid black;
  padding: 15px;
  text-align: center;
}

.crabTable tr:nth-child(even) {background-color: #F5F5F5;}
.crabTable tr:nth-child(odd) {background-color: #E8E8E8;}

#clickable {
  cursor: pointer
}

.center {
  margin: auto;
}

.vspacer5 { width:1px;height:5px;clear:both;}
.vspacer10 { width:1px;height:10px;clear:both;}
.vspacer50 { width:1px;height:50px;clear:both;}
.vspacer80 { width:1px;height:80px;clear:both;}